import React, { useRef } from "react"

import clsx from "clsx"

import SwitzerTypography from "../SwitzerTypography"

import { StoryType } from "./Story"
import StoryAvatar from "./StoryAvatar"

type Props = {
  data: StoryType
  className?: string
  onClick: () => void
}

const StoryCard = ({ data, className, onClick }: Props) => {
  const video = useRef<HTMLVideoElement | null>(null)
  return (
    <button
      className={clsx(className, "overflow-hidden rounded-2xl relative")}
      onClick={onClick}
      onMouseEnter={() => {
        if (video.current) {
          if (video.current.paused) {
            video.current.play()
          }
        }
      }}
      onMouseLeave={() => {
        if (video.current) {
          if (!video.current.paused) {
            video.current.pause()
            video.current.currentTime = 0
          }
        }
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full z-[1]"
        style={{
          background: "rgba(0, 0, 0, 0.5)",
        }}
      />
      <div className="flex flex-col items-center w-full absolute -translate-x-2/4 -translate-y-2/4 rounded-full left-2/4 top-2/4 z-[2]">
        <StoryAvatar className="mb-1" imgSrc={data.img} size="lg" />
        <SwitzerTypography
          color="common.white.500"
          fontFamily="Switzer Medium"
          variant="largeBody"
        >
          {data.name}
        </SwitzerTypography>
        <SwitzerTypography color="surface.200" fontFamily="Switzer Regular">
          {data.position}
        </SwitzerTypography>
      </div>
      <video
        className="object-cover w-full h-full bg-white"
        id="video"
        // poster={data.thumbnail}
        ref={video}
        loop
        muted
      >
        <source src={data.video} type="video/mp4" />
      </video>
    </button>
  )
}

export default StoryCard
