import React, { useEffect, useRef, useState } from "react"

import { IconButton } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { Play, Xmark } from "iconoir-react"

import SlidingSheet from "components/shared/SlidingSheet"
import useDetectCountry from "utils/hooks/useDetectCountry"
import { getITOVideoSrc } from "utils/ito/utils"

const useStyles = createUseStyles(() => ({
  buttonContainer: {
    background: "rgba(0,0,0,0.3)",
  },
  playBtn: {
    background: "rgba(24, 24, 24, 0.85)",
    transition: "transform 0.2s",
    animation: "$pulse 1.5s ease-in-out infinite",
    "&:hover": {
      transform: "scale(1.3)",
    },
  },
  container: {
    boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
  },
  scaleAnimation: { transform: "scale(1)", transition: "transform 0.5s ease" },
  sheetContainer: {
    background: "rgba(0, 0, 0, 0.80)",
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(24, 24, 24, 0.5)",
    },
    "100%": {
      boxShadow: "0 0 0 12px rgba(24, 24, 24, 0)",
    },
  },
}))

type Props = {
  className?: string
  isHeroSection?: boolean
  poster?: string
}

const gifUrl =
  "https://assets.suraasa.com/ito/2023/video/ito-launch-thumbnail.mp4"

const HeroSectionVideo = ({ className, isHeroSection, poster }: Props) => {
  const classes = useStyles()
  const divRef = useRef<HTMLDivElement | null>(null)
  const [playVideo, setPlayVideo] = useState(false)

  const country = useDetectCountry()

  const url = getITOVideoSrc(country)

  const onClose = () => {
    setPlayVideo(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY
      const newScale = 1 + scrolled * 0.001
      const limitedScale = Math.min(newScale, 2)

      if (divRef.current && limitedScale < 1.15) {
        divRef.current.style.transform = `scale(${limitedScale})`
      }
    }
    if (isHeroSection) window.addEventListener("scroll", handleScroll)

    return () => {
      if (isHeroSection) window.removeEventListener("scroll", handleScroll)
    }
  }, [isHeroSection])

  return (
    <>
      <div
        className={clsx(
          "rounded-3xl max-w-[967px] mx-auto overflow-hidden  relative",
          className,
          {
            [classes.scaleAnimation]: isHeroSection,
            [classes.container]: isHeroSection,
            "md:border-[12px] sm:border-[8px] border-[4px] border-solid border-[rgba(255,255,255,0.80)] sm:mx-auto mx-1.5":
              isHeroSection,
          }
        )}
        ref={divRef}
        role="button"
        tabIndex={0}
        onClick={() => {
          setPlayVideo(true)
        }}
        onKeyDown={e => {
          if (e.key === "Space") {
            setPlayVideo(true)
          }
        }}
      >
        <div
          className={clsx(
            classes.buttonContainer,
            "absolute top-0 left-0 flex items-center justify-center w-full h-full z-[1]"
          )}
        >
          <button
            className={clsx(classes.playBtn, "rounded-full relative", {
              "md:w-11 md:h-11 sm:w-8 sm:h-8 w-5 h-5": isHeroSection,
              "md:w-9 md:h-9  sm:w-8 sm:h-8 w-5 h-5": !isHeroSection,
            })}
            onClick={() => {
              setPlayVideo(true)
            }}
          >
            <Play
              className={clsx(
                "absolute  -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4",
                {
                  "md:h-[59px] md:w-7 sm:w-4 sm:h-4.5 w-2 w-2.5": isHeroSection,
                  "md:h-5 md:w-6 sm:w-4 sm:h-4.5 w-2 w-2.5": !isHeroSection,
                }
              )}
              color="white"
              fill="white"
            />
          </button>
        </div>
        <video className="w-full bg-white" poster={poster} autoPlay loop muted>
          <source src={gifUrl} type="video/mp4" />
        </video>
      </div>
      <SlidingSheet
        className={clsx(classes.sheetContainer, "relative overflow-hidden")}
        open={playVideo}
        fullScreen
        unmountOnExit
        onClose={onClose}
      >
        <div className="relative h-full">
          <IconButton
            className="absolute z-[2] top-3 right-3"
            color="white"
            size="md"
            onClick={onClose}
          >
            <Xmark fontSize={30} />
          </IconButton>

          <div className="absolute text-white -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 max-w-[130vh] w-[90vw]">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              className="w-full bg-white"
              poster={poster}
              autoPlay
              controls
            >
              <source src={url} type="video/mp4" />
            </video>
          </div>
        </div>
      </SlidingSheet>
    </>
  )
}

export default HeroSectionVideo
