import React from "react"

import { Button, Container, Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import Badges from "public/assets/ito/awards-and-prizes/badges.webp"
import ITU from "public/assets/ito/awards-and-prizes/ITU.webp"
import GridSquare from "public/assets/ito/hero-section/grid-square.svg"
import GridSquareMini from "public/assets/ito/hero-section/grid-square-mini.svg"
import { createUseStyles } from "react-jss"

import { ArrowRight } from "iconoir-react"

import { itoSections } from "utils/constants"
import customTheme from "utils/customTheme"
import { PriceCategories } from "utils/ito/utils"

import { itoPageSections } from "../NavigationSection"
import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(() => ({
  cashPrize: {
    background: "linear-gradient(144deg, #F6B952 0%, #FFFBD3 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    animation: "$gradient 1s ease infinite",
    backgroundSize: "200% 200%",
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
  cardTitle: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "120%",
    letterSpacing: "-0.28px",
  },
  listTag: {
    "& li": {
      fontFamily: "Switzer Regular",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "130%",
      listStyleType: "initial",
      color: customTheme.midnight,
    },
  },
  top100Title: {
    background:
      "conic-gradient(from 181deg at 48.50% 50.00%, #CCCBCC 26.24999910593033deg, #000 88.12500178813934deg, #DADADA 146.25deg, #000 204.37499284744263deg, #AFAFAF 268.1250071525574deg, #000 336.82496309280396deg)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    // animation: "$gradient 5s ease infinite",
    backgroundSize: "250% 250%",
  },
  title1: {
    fontFamily: "Switzer Bold",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.48px",
  },
  subTitle1: {
    fontFamily: "Switzer Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "130%",
  },
  top10Title: {
    background:
      "conic-gradient(from 177.4deg at 44.09% 49.87%, #744B3A -28.12deg, #A26840 26.25deg, #C9996D 61.12deg, #855441 149.84deg, #A67E64 171.97deg, #8F653B 197.94deg, #673D22 225.95deg, #90604C 255.19deg, #B28472 278.6deg, #744B3A 331.88deg, #A26840 386.25deg)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    // animation: "$gradient 5s ease infinite",
    backgroundSize: "250% 250%",
  },
  top3Title: {
    background:
      "conic-gradient(from 193.02deg at 41.71% 57.14%, #3F1F0F -28.12deg, #E3A455 26.25deg, #EFCB83 110.05deg, #F7C666 149.84deg, #F0BE79 171.97deg, #8F653B 197.94deg, #673D22 225.95deg, #BA7F3B 255.19deg, #EEBC70 278.6deg, #3F1F0F 331.88deg, #E3A455 386.25deg)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    // animation: "$gradient 5s ease infinite",
    backgroundSize: "250% 250%",
  },
  containerBg: {
    backgroundImage: `url(${GridSquare.src}), radial-gradient(at -3% -12%, rgba(249, 205, 94, 1) 0,#050124 30%)`,
  },
  gridBg: {
    backgroundImage: `url(${GridSquareMini.src})`,
  },
}))

const ITUBanner = ({
  getPrice,
}: {
  getPrice: (key: PriceCategories<"2023">) => string
}) => (
  <div
    className="relative p-3 overflow-hidden rounded-2xl"
    style={{
      background: "#FFD2E0",
    }}
  >
    <Image
      alt="ITU"
      className="absolute opacity-25 -right-1/4 sm:right-0 sm:opacity-50 z-1"
      src={ITU}
    />
    <SwitzerTypography
      className="relative z-2"
      style={{
        fontFamily: "Switzer Bold",
        color: "#820027",
        fontSize: 32,
        letterSpacing: "-1.28px",
        lineHeight: 1.2,
      }}
    >
      Top 800
    </SwitzerTypography>
    <SwitzerTypography
      className="mb-3 mt-1 z-2 relative sm:w-[calc(100%-292px)]"
      color="onSurface.800"
      style={{
        color: "#050124",
      }}
      variant="smallBody"
    >
      Get a {getPrice("ituScholarship")} scholarship grant for admission into
      the online Master's in Education degree by International Teachers'
      University (Approved by US Govt).
    </SwitzerTypography>
    <Button
      className="flex gap-0.5 p-0.5 items-center z-2 relative"
      component="a"
      endAdornment={<ArrowRight height={20} width={20} />}
      href="https://internationalteachers.university/"
      nudge="left"
      rel="noreferrer"
      style={{
        color: "#820027",
      }}
      target="_blank"
      variant="text"
    >
      <Typography variant="button">Learn more about ITU</Typography>
    </Button>
  </div>
)

const CashPrizeAndReward = ({
  getPrice,
  spacing,
}: {
  getPrice: (key: PriceCategories<"2023">) => string
  spacing: string
}) => {
  const classes = useStyles()
  return (
    <div className={spacing} id={itoPageSections.PRIZE}>
      <div className={clsx(classes.containerBg)}>
        <Container>
          <section className="py-3 md:py-12 sm:py-5 " id={itoSections.PRIZES}>
            <SwitzerTypography
              className={clsx("mb-6", classes.cashPrize)}
              fontFamily="Switzer Bold"
              variant="title1"
            >
              Cash Prize & Rewards
            </SwitzerTypography>

            <div className="grid md:grid-cols-[50%_50%] md:grid-rows-[auto] grid-cols-[auto] grid-rows-[50%_50%] gap-1.5 mb-4">
              <div
                className={clsx(
                  classes.gridBg,
                  "w-full p-3 rounded-2xl bg-ito-iris-100 h-full"
                )}
              >
                <span
                  className={clsx(classes.cardTitle, "text-ito-iris-700 mb-1")}
                >
                  All Participants
                </span>
                <ul className={clsx(classes.listTag, "text-midnight pl-2")}>
                  <li>International Certificate of Participation</li>
                  <li>
                    Access to an Exclusive Community of Teachers on Suraasa
                    (Leading Teacher Upskilling & Growth Platform)
                  </li>
                </ul>
              </div>

              <div
                className={clsx(
                  classes.gridBg,
                  "rounded-2xl bg-[#F1F1F1] w-full p-3 h-full"
                )}
              >
                <div className="flex flex-wrap items-center gap-1 mb-1">
                  <span
                    className={clsx(classes.cardTitle, classes.top100Title)}
                  >
                    Top 100
                  </span>

                  <Image
                    alt="top-100"
                    className="object-contain h-4 w-fit"
                    src={Badges}
                  />
                </div>
                <ul className={clsx(classes.listTag, "text-midnight pl-2")}>
                  <li>Cash Prize of {getPrice("cashPrizeBronze")}</li>
                  <li>Recognition at state & national levels</li>
                  <li>Direct entry to Teacher Impact Awards</li>
                </ul>
              </div>
            </div>

            <div className="mb-3">
              <SwitzerTypography
                className="mb-1.5"
                color="common.white.500"
                fontFamily="Switzer Bold"
                variant="title2"
              >
                Teacher Impact Awards
              </SwitzerTypography>
              <SwitzerTypography
                color="common.white.500"
                fontFamily="Switzer Regular"
                variant="smallBody"
              >
                Global initiative by Suraasa to give teachers their
                well-deserved credit & recognition by sharing their success
                stories with the world
              </SwitzerTypography>
            </div>

            <div className="grid gap-1.5 mb-4">
              <div
                className={clsx(
                  classes.gridBg,
                  "rounded-2xl bg-[#FFEFE3] w-full p-3"
                )}
              >
                <span
                  className={clsx(
                    classes.cardTitle,
                    classes.top10Title,
                    "mb-1"
                  )}
                >
                  Top 10
                </span>
                <ul className={clsx(classes.listTag, "text-midnight pl-2")}>
                  <li>Cash Prize of {getPrice("cashPrizeSilver")}</li>
                  <li>Recognition at state, national & international levels</li>
                </ul>
              </div>
              <div
                className={clsx(
                  classes.gridBg,
                  "rounded-2xl bg-[#FFF8DB] w-full p-3"
                )}
              >
                <span
                  className={clsx(classes.cardTitle, classes.top3Title, "mb-1")}
                >
                  Top 3
                </span>
                <ul className={clsx(classes.listTag, "text-midnight pl-2")}>
                  <li>Winner: cash prize of {getPrice("cashPrizeFirst")}</li>
                  <li>
                    1st Runner up: cash prize of {getPrice("cashPrizeSecond")}
                  </li>
                  <li>
                    2nd Runner up: cash prize of {getPrice("cashPrizeThird")}
                  </li>
                </ul>
              </div>
              <SwitzerTypography
                className="flex items-center mt-5 mb-1.5"
                color="common.white.500"
                fontFamily="Switzer Bold"
                variant="title2"
              >
                Scholarship Fund of {getPrice("ituScholarshipTotal")}
                &nbsp;&nbsp;🎁
              </SwitzerTypography>
              <ITUBanner getPrice={getPrice} />
            </div>
          </section>
        </Container>
      </div>
    </div>
  )
}

export default CashPrizeAndReward
