import React, { MutableRefObject } from "react"

import Image from "next/image"
import { createUseStyles } from "react-jss"

import { globalSchools, uaeSchools } from "components/ito/2022/HeroSection"
import SlidingBanner from "components/shared/SlidingBanner"

import SwitzerTypography from "../SwitzerTypography"

type Props = {
  className?: string
  countryCode?: string
  passRef?: MutableRefObject<HTMLDivElement | null>
}

const useStyles = createUseStyles(() => ({
  greyScaleImg: {
    "& img": {
      filter: "grayscale(100%)",
      objectFit: "contain",
    },
  },
}))

const TrustedSchools = ({ className, countryCode, passRef }: Props) => {
  const classes = useStyles()
  const images = countryCode === "AE" ? uaeSchools : globalSchools
  return (
    <div className={className} ref={passRef}>
      <SwitzerTypography
        className="mb-4 text-center"
        color="onSurface.800"
        fontFamily="Switzer Bold"
        variant="title2"
      >
        Trusted by Teachers from 5500+ Schools & 95+ Countries
      </SwitzerTypography>
      <div className={classes.greyScaleImg}>
        <SlidingBanner
          className="mb-1.75"
          // eslint-disable-next-line react/no-unstable-nested-components
          component={data => (
            <div className="mx-1">
              <Image
                alt="img"
                className="object-contain h-6 md:h-10 sm:h-5 w-fit"
                src={data}
              />
            </div>
          )}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          images={images.map(school => school.img)}
          repeatImageTimes={2}
        />
      </div>
    </div>
  )
}

export default TrustedSchools
