import React from "react"

import clsx from "clsx"

const labels = ["Days", "Hours", "Mins", "Secs"]

const Countdown = ({
  hours = 0,
  days = 0,
  minutes = 0,
  seconds = 0,
  isMini,
  className,
}: {
  hours: number
  days: number
  minutes: number
  seconds: number
  isMini?: boolean
  className?: string
}) => (
  <div
    className={clsx("flex items-center", className, {
      "gap-1.75": !isMini,
      "gap-0.5": isMini,
    })}
  >
    {[days, hours, minutes, seconds].map((i, index) => (
      <div
        className={clsx("w-[43.2px] text-center", {
          "flex flex-col items-center": isMini,
        })}
        id={labels[index]}
        key={labels[index]}
      >
        <span
          className={clsx(
            "block mx-auto  font-bold bg-white text-black rounded max-w-[44px]",
            { "p-0.5 px-0.75 mb-0.5 text-2xl": !isMini }
          )}
        >
          {i.toString().padStart(2, "0")}
        </span>
        <span className="text-xs font-medium">{labels[index]}</span>
      </div>
    ))}
  </div>
)

export default Countdown
