import React, { useState } from "react"

import { IconButton, Typography } from "@suraasa/placebo-ui-legacy"

import { Xmark } from "iconoir-react"

import NoSSR from "components/shared/NoSSR"
import SlidingSheet from "components/shared/SlidingSheet"

type Props = {
  render: (handleOpen: () => void) => JSX.Element
}

const ReportSheet = ({ render }: Props) => {
  const [openReportSheet, setOpenReportSheet] = useState(false)

  return (
    <NoSSR>
      {render(() => setOpenReportSheet(true))}
      <SlidingSheet
        className="w-[min(100vw,900px)] max-h-[98vh] rounded-xl overflow-hidden"
        open={openReportSheet}
        onClose={() => setOpenReportSheet(false)}
      >
        <div className="flex items-center gap-1 px-2 py-2 border-b-2">
          <IconButton color="black" onClick={() => setOpenReportSheet(false)}>
            <Xmark />
          </IconButton>
          <Typography variant="title2">Pedagogy Report</Typography>
        </div>

        {/* TODO: This needs to be updated for 2023 */}
        <object
          data="https://assets.suraasa.com/ito/Teacher%20Report%20Sample.pdf"
          height="935"
          title="teacher report"
          width="100%"
        />
      </SlidingSheet>
    </NoSSR>
  )
}

export default ReportSheet
