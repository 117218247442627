import React from "react"

import { CheckCircle } from "iconoir-react"

import SwitzerTypography from "../SwitzerTypography"

type Props = { label: string }

const Tags = ({ label }: Props) => (
  <div
    className="py-1 px-1.5 rounded-full flex w-fit border border-white"
    style={{
      background: "rgba(255, 255, 255, 0.60)",
    }}
  >
    <CheckCircle className="w-2.5 h-2.5 shrink-0 mr-1 text-ito-mint-700" />
    <SwitzerTypography
      color="onSurface.700"
      fontFamily="Switzer Medium"
      variant="body"
    >
      {label}
    </SwitzerTypography>
  </div>
)

export default Tags
