import React, { useEffect, useRef } from "react"

import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import Img97Percent from "public/assets/ito/hero-section/97-percent.webp"
import Img97PercentMobile from "public/assets/ito/hero-section/97-percent-mobile.webp"
import CurlyPath from "public/assets/ito/hero-section/curl-path.svg"
import GridSquare from "public/assets/ito/hero-section/grid-square.svg"
import LeftQuoteMark from "public/assets/ito/hero-section/left-quote-mark.svg"
import LeftQuoteMarkBig from "public/assets/ito/hero-section/left-quote-mark-big.svg"
import Top100MobAED from "public/assets/ito/hero-section/notes/mobile/mob-aed.webp"
import Top100MobINR from "public/assets/ito/hero-section/notes/mobile/mob-inr.webp"
import Top100MobUSD from "public/assets/ito/hero-section/notes/mobile/mob-usd.webp"
import Top100WebAED from "public/assets/ito/hero-section/notes/web/web-aed.webp"
import Top100WebINR from "public/assets/ito/hero-section/notes/web/web-inr.webp"
import Top100WebUSD from "public/assets/ito/hero-section/notes/web/web-usd.webp"
import RightQuoteMark from "public/assets/ito/hero-section/right-quote-mark.svg"
import RightQuoteMarkBig from "public/assets/ito/hero-section/right-quote-mark-big.svg"
// Teachers
import Teacher1 from "public/assets/ito/hero-section/teachers/teacher-1.webp"
import Teacher2 from "public/assets/ito/hero-section/teachers/teacher-2.webp"
import Teacher3 from "public/assets/ito/hero-section/teachers/teacher-3.webp"
import Teacher4 from "public/assets/ito/hero-section/teachers/teacher-4.webp"
import Teacher5 from "public/assets/ito/hero-section/teachers/teacher-5.webp"
import Teacher6 from "public/assets/ito/hero-section/teachers/teacher-6.webp"
import Teacher7 from "public/assets/ito/hero-section/teachers/teacher-7.webp"
import Teacher8 from "public/assets/ito/hero-section/teachers/teacher-8.webp"
import Teacher9 from "public/assets/ito/hero-section/teachers/teacher-9.webp"
import Teacher10 from "public/assets/ito/hero-section/teachers/teacher-10.webp"
import Teacher11 from "public/assets/ito/hero-section/teachers/teacher-11.webp"
import Teacher12 from "public/assets/ito/hero-section/teachers/teacher-12.webp"
import Teacher13 from "public/assets/ito/hero-section/teachers/teacher-13.webp"
import Teacher14 from "public/assets/ito/hero-section/teachers/teacher-14.webp"
import Teacher15 from "public/assets/ito/hero-section/teachers/teacher-15.webp"
import Teacher16 from "public/assets/ito/hero-section/teachers/teacher-16.webp"
import Teacher17 from "public/assets/ito/hero-section/teachers/teacher-17.webp"
import Teacher18 from "public/assets/ito/hero-section/teachers/teacher-18.webp"
import Teacher19 from "public/assets/ito/hero-section/teachers/teacher-19.webp"
import Teacher20 from "public/assets/ito/hero-section/teachers/teacher-20.webp"
import Teacher21 from "public/assets/ito/hero-section/teachers/teacher-21.webp"
import Teacher22 from "public/assets/ito/hero-section/teachers/teacher-22.webp"
import Teacher23 from "public/assets/ito/hero-section/teachers/teacher-23.webp"
import Teacher24 from "public/assets/ito/hero-section/teachers/teacher-24.webp"
import Teacher26 from "public/assets/ito/hero-section/teachers/teacher-26.webp"
import Teacher27 from "public/assets/ito/hero-section/teachers/teacher-27.webp"
import Teacher29 from "public/assets/ito/hero-section/teachers/teacher-29.webp"
import Teacher32 from "public/assets/ito/hero-section/teachers/teacher-32.webp"
import Teacher33 from "public/assets/ito/hero-section/teachers/teacher-33.webp"
import Teacher35 from "public/assets/ito/hero-section/teachers/teacher-35.webp"
import Teacher38 from "public/assets/ito/hero-section/teachers/teacher-38.webp"
import Teacher39 from "public/assets/ito/hero-section/teachers/teacher-39.webp"
import Teacher40 from "public/assets/ito/hero-section/teachers/teacher-40.webp"
import Teacher41 from "public/assets/ito/hero-section/teachers/teacher-41.webp"
import Teacher42 from "public/assets/ito/hero-section/teachers/teacher-42.webp"
import Teacher43 from "public/assets/ito/hero-section/teachers/teacher-43.webp"
import Teacher44 from "public/assets/ito/hero-section/teachers/teacher-44.webp"
import Teacher45 from "public/assets/ito/hero-section/teachers/teacher-45.webp"
import Teacher46 from "public/assets/ito/hero-section/teachers/teacher-46.webp"
import Teacher47 from "public/assets/ito/hero-section/teachers/teacher-47.webp"
import Teacher48 from "public/assets/ito/hero-section/teachers/teacher-48.webp"
import Teacher49 from "public/assets/ito/hero-section/teachers/teacher-49.webp"
import Teacher50 from "public/assets/ito/hero-section/teachers/teacher-50.webp"
import Teacher51 from "public/assets/ito/hero-section/teachers/teacher-51.webp"
import Teacher52 from "public/assets/ito/hero-section/teachers/teacher-52.webp"
import Teacher53 from "public/assets/ito/hero-section/teachers/teacher-53.webp"
import Teacher54 from "public/assets/ito/hero-section/teachers/teacher-54.webp"
import Teacher55 from "public/assets/ito/hero-section/teachers/teacher-55.webp"
import Teacher56 from "public/assets/ito/hero-section/teachers/teacher-56.webp"
import Teacher57 from "public/assets/ito/hero-section/teachers/teacher-57.webp"
import Teacher58 from "public/assets/ito/hero-section/teachers/teacher-58.webp"
import Teacher59 from "public/assets/ito/hero-section/teachers/teacher-59.webp"
import Teacher60 from "public/assets/ito/hero-section/teachers/teacher-60.webp"
import Teacher61 from "public/assets/ito/hero-section/teachers/teacher-61.webp"
import Teacher62 from "public/assets/ito/hero-section/teachers/teacher-62.webp"
import Teacher63 from "public/assets/ito/hero-section/teachers/teacher-63.webp"
import Teacher64 from "public/assets/ito/hero-section/teachers/teacher-64.webp"
import Teacher65 from "public/assets/ito/hero-section/teachers/teacher-65.webp"
import Thumbnail from "public/assets/ito/hero-section/thumbnail.webp"
import OlympiadLogoFull from "public/assets/olympiad/ito-logo.svg"
import { createUseStyles, useTheme } from "react-jss"

// import { globalSchools, uaeSchools } from "components/ito/2022/HeroSection"
import RegistrationStatusButton from "components/ito/2022/RegistrationStatusButton"
import SlidingBanner from "components/shared/SlidingBanner"
import { TRACKING_DATA } from "utils/googleAnalytics"
import { PriceCategories } from "utils/ito/utils"

import SwitzerTypography from "../SwitzerTypography"

import { Gradient } from "./Gradient"
import HeroSectionVideo from "./HeroSectionVideo"
import ReviewButton from "./ReviewButton"
import Tags from "./Tags"

export const teachersList1 = [
  Teacher1,
  Teacher2,
  Teacher50,
  Teacher3,
  Teacher45,
  Teacher4,
  Teacher51,
  Teacher5,
  Teacher42,
  Teacher6,
  Teacher7,
  Teacher8,
  Teacher52,
  Teacher9,
  Teacher10,
  Teacher43,
  Teacher11,
  Teacher12,
  Teacher53,
  Teacher24,
  Teacher26,
  Teacher54,
  Teacher27,
  Teacher44,
  Teacher29,
  Teacher55,
  Teacher56,
  Teacher42,
  Teacher32,
  Teacher57,
  Teacher33,
  Teacher45,
]
export const teachersList2 = [
  Teacher13,
  Teacher14,
  Teacher15,
  Teacher58,
  Teacher16,
  Teacher17,
  Teacher46,
  Teacher18,
  Teacher63,
  Teacher19,
  Teacher20,
  Teacher59,
  Teacher21,
  Teacher22,
  Teacher47,
  Teacher23,
  Teacher64,
  Teacher35,
  Teacher60,
  Teacher65,
  Teacher48,
  Teacher38,
  Teacher39,
  Teacher61,
  Teacher40,
  Teacher62,
  Teacher41,
  Teacher49,
]

type Props = {
  showVideo?: boolean
  countryCode?: string
  variant?: "J" | "K" | "L"
  getPrice: (key: PriceCategories<"2023">) => string
  waveEffect?: boolean
}

const useStyles = createUseStyles(theme => ({
  title: {
    textAlign: "center",

    fontFamily: "Switzer Bold",
    fontWeight: "700",
    fontSize: "56px",
    lineHeight: "normal",
    letterSpacing: "-1.12px",
    fontStyle: "normal",

    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      lineHeight: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "27px",
      lineHeight: "41px",
    },
  },

  greyScaleImg: {
    "& img": {
      filter: "grayscale(100%)",
      objectFit: "contain",
    },
  },
  container: {
    backgroundImage: `linear-gradient(90deg, rgba(105,219,123,0.2) 0%, rgba(235,150,77,0.2) 20%, rgba(235,72,63,0.2) 40%, rgba(234,89,202,0.2) 60%, rgba(52,109,242,0.2) 80%, rgba(116,63,245,0.2) 100%),url(${GridSquare.src})`,
  },
  whiteGradient: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%)",
    [theme.breakpoints.down("xs")]: {
      background:
        "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",
    },
  },
  gradientAnimation: {
    clipPath: "ellipse(100% 71% at 50% 0%)",
    [theme.breakpoints.down("sm")]: {
      clipPath: "ellipse(100% 71% at 50% 0%)",
    },
    [theme.breakpoints.down("xs")]: {
      clipPath: "ellipse(160% 80% at 50% 0%)",
    },
  },
}))
/* eslint-disable @typescript-eslint/no-unsafe-return */
const Top100WebNotes = (item?: string) => {
  if (item === "IN") {
    return Top100WebINR
  }
  if (item === "AE") {
    return Top100WebAED
  }
  return Top100WebUSD
}
const Top100MobNotes = (item?: string) => {
  if (item === "IN") {
    return Top100MobINR
  }
  if (item === "AE") {
    return Top100MobAED
  }
  return Top100MobUSD
}

const HeroSection = ({
  variant,
  showVideo,
  countryCode,
  getPrice,
  waveEffect,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const canvaRef = useRef<HTMLCanvasElement | null>(null)
  // eslint-disable-next-line no-nested-ternary
  const top100Check = isMdUp
    ? Top100WebNotes(countryCode)
    : isSmDown
    ? Top100MobNotes(countryCode)
    : null

  // eslint-disable-next-line no-nested-ternary
  const img97PercentageCheck = isMdUp
    ? Img97Percent
    : isSmDown
    ? Img97PercentMobile
    : null

  useEffect(() => {
    if (waveEffect && canvaRef.current) {
      const gradient = new Gradient()
      // @ts-ignore ts is not defined for this
      gradient.initGradient(`#${canvaRef.current.id}`)

      return () => {
        gradient.disconnect()
      }
    }
  }, [waveEffect, canvaRef])

  return (
    <div>
      <div
        className={clsx("relative", {
          [classes.container]: !waveEffect,
        })}
      >
        {waveEffect && (
          <canvas
            className={clsx(
              classes.gradientAnimation,
              "absolute top-0 left-0 w-full h-full"
            )}
            data-BlueRatio={0.9}
            data-darkRatio={0.9}
            data-greenRatio={0.9}
            data-redRatio={0.9}
            data-variablePart={0.9}
            height={32}
            id="gradient-canvas"
            ref={canvaRef}
            width={32}
          />
        )}
        {!waveEffect && (
          <div
            className={clsx(
              classes.whiteGradient,
              "absolute w-full h-full z-[1]"
            )}
          />
        )}
        <div
          className="z-[2] pt-6"
          style={{
            position: "inherit",
          }}
        >
          <Image
            alt="ito-logo"
            className={clsx("mx-auto mb-5 aspect-[auto_366_/_29] h-[29px]", {
              "brightness-0 invert-[1]": waveEffect,
            })}
            height={29}
            loading="eager"
            src={OlympiadLogoFull}
            width={366}
            priority
          />
          <div
            className={clsx("sm:mx-auto mx-4 mb-3", classes.title, {
              "text-ito-midnight": !waveEffect,
              "text-white": waveEffect,
            })}
          >
            <span className="relative inline-block w-fit">
              Discover
              <div className="absolute bottom-0 w-full">
                <Image alt="curly-path" className="w-full" src={CurlyPath} />
              </div>
            </span>{" "}
            your{" "}
            <div
              className="relative mx-auto w-fit"
              style={{
                wordBreak: "break-word",
              }}
            >
              #TrueTeachingPotential{" "}
              <div className="absolute -top-3 -right-3">
                <Image
                  alt="right-quote-mark"
                  src={isSmDown ? RightQuoteMark : RightQuoteMarkBig}
                />
              </div>
              <div className="absolute -top-2.5 -left-3">
                <Image
                  alt="left-quote-mark"
                  src={isSmDown ? LeftQuoteMark : LeftQuoteMarkBig}
                />
              </div>
            </div>
          </div>

          {(variant === "J" || variant === "K") && (
            <div
              className={clsx("px-1 mb-5 text-center", {
                "text-ito-midnight": !waveEffect,
                "text-white": waveEffect,
              })}
            >
              <SwitzerTypography
                className="mb-3"
                fontFamily="Switzer Medium"
                variant="largeBody"
              >
                Participate in the largest Teachers' Olympiad to get:
              </SwitzerTypography>
              <div className="mb-3 flex gap-1.5 flex-wrap max-w-[966px] w-full mx-auto justify-center">
                <Tags label="Feedback Report on Teaching Skills" />
                <Tags label="International Certificates" />
                <Tags label=" 6 Certified Expert-Led Live Sessions" />
                <Tags label="Global Teacher Community" />
                <Tags
                  label={`Winner Cash Prizes Upto ${getPrice(
                    "totalPrizePool"
                  )}`}
                />
              </div>
              {variant === "J" && (
                <SwitzerTypography
                  fontFamily="Switzer Bold"
                  variant="largeBody"
                >
                  Thousands of teachers from 95+ countries have already
                  registered!
                </SwitzerTypography>
              )}
              {variant === "K" && (
                <SwitzerTypography
                  fontFamily="Switzer Bold"
                  variant="largeBody"
                >
                  Total Benefits worth {getPrice("totalRewards")} on
                  participation!
                </SwitzerTypography>
              )}
            </div>
          )}

          {variant === "L" && (
            <SwitzerTypography
              className="px-2 mb-5 text-center"
              fontFamily="Switzer Medium"
              variant="largeBody"
            >
              Get a detailed feedback report on your teaching skills with
              international certificates & huge cash prizes. <br />
              <strong>
                Thousands of teachers from 95+ countries and 2,015+ schools have
                already registered!
              </strong>
            </SwitzerTypography>
          )}

          <RegistrationStatusButton
            className="mb-3 mx-auto !block"
            data-source={TRACKING_DATA.source.itoLandingPageHero}
            gradientButton
          />
          <ReviewButton
            className={clsx("mx-auto p-1.5 border-onSurface-100 border", {
              "mb-8": !showVideo,
              "mb-11": showVideo,
            })}
            glassEffect={waveEffect}
          />
          {showVideo && (
            <Container>
              <HeroSectionVideo
                className="mb-20"
                poster={Thumbnail.src}
                isHeroSection
              />
            </Container>
          )}
          <div
            className={clsx("relative", {
              "pb-[149px] md:pb-[128px]": !waveEffect,
              "pb-[50px]": waveEffect,
            })}
          >
            {top100Check && !waveEffect && (
              <div className="w-[273px] h-[112px] absolute md:left-8 md:top-[-137px] md:bottom-auto bottom-3">
                <Image
                  alt="top-100"
                  className="max-w-[200px] md:max-w-[250px]"
                  src={top100Check}
                />
              </div>
            )}
            {img97PercentageCheck && !waveEffect && (
              <div className="md:w-[278px] w-[168px] h-[64px] absolute md:top-auto md:bottom-7 -top-8 md:right-0 right-2">
                <Image alt="97-percent" src={img97PercentageCheck} />
              </div>
            )}
            <SlidingBanner
              className="mb-1.75"
              // eslint-disable-next-line react/no-unstable-nested-components
              component={data => (
                <div className="mx-1">
                  <Image
                    alt="img"
                    className="w-8 h-8 sm:w-11 sm:h-11"
                    src={data}
                  />
                </div>
              )}
              images={teachersList1}
              repeatImageTimes={1}
              speedTime={250}
            />
            <SlidingBanner
              // eslint-disable-next-line react/no-unstable-nested-components
              component={data => (
                <div className="mx-1">
                  <Image
                    alt="img"
                    className="w-8 h-8 sm:w-11 sm:h-11"
                    src={data}
                  />
                </div>
              )}
              images={teachersList2}
              repeatImageTimes={1}
              speedTime={250}
              reverseAnimation
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
