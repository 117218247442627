import React from "react"

import clsx from "clsx"
import Image, { StaticImageData } from "next/image"
import { createUseStyles } from "react-jss"

const DURATION = 4500
const useStyles = createUseStyles(() => ({
  container: {
    transition: "transform 0.5s",
  },
  hoverAnimation: {
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
  circle1: {
    fill: "none",
    strokeWidth: 3,
    strokeDasharray: 1,
    strokeDashoffset: 0,
    strokeLinecap: "round",
    transformOrigin: "center",
    // animation: "$defaultLoading 4500ms ease-in-out infinite",
    "&.noAnimation": { strokeDasharray: 1 },
    // "&.once": {
    //   animation: `$defaultLoading ${DURATION}ms ease-in-out`,
    // },
    // "&.infinite": {
    //   strokeDasharray: 10,
    //   animation: `$spin ${DURATION}ms linear infinite`,
    // },
  },
  circle1Animation: {
    "&.once": {
      animation: `$defaultLoading ${DURATION}ms ease-in-out`,
    },
    "&.infinite": {
      strokeDasharray: 10,
      animation: `$spin ${DURATION}ms linear infinite`,
    },
  },

  circle2: {
    fill: "none",
    stroke: "transparent",
    strokeWidth: 5,
    strokeLinecap: "round",
    strokeDasharray: 282,
    strokeDashoffset: "-282",
    transformOrigin: "center",
    transform: "rotate(270deg)",
  },
  circle2Animation: {
    animation: `$circleSpin ${DURATION}ms ease-in-out`,
  },
  "@keyframes spin": {
    from: {
      strokeDashoffset: 0,
    },
    to: {
      strokeDashoffset: "-282",
    },
  },
  "@keyframes circleSpin": {
    "0%": {
      stroke: "white",
      strokeDashoffset: 0,
    },
    "90%": { stroke: "white" },
    "100%": {
      stroke: "transparent",
      strokeDashoffset: "-282",
    },
  },
  "@keyframes defaultLoading": {
    from: {
      strokeDasharray: 10,
    },
    to: {
      strokeDasharray: 1,
    },
  },
}))
const StoryAvatar = ({
  onClick,
  imgSrc,
  animation = "none",
  className,
  runAnimation = false,
  size,
}: {
  onClick?: () => void
  imgSrc: StaticImageData
  animation?: "none" | "infinite" | "once" | "stale"
  className?: string
  runAnimation?: boolean
  size?: "xs" | "sm" | "md" | "lg" | "xl"
}) => {
  const classes = useStyles()
  const Component = onClick ? "button" : "div"

  return (
    <Component
      className={clsx(
        classes.container,
        "relative overflow-hidden !block",
        className,
        {
          [classes.hoverAnimation]: animation !== "stale",
          "w-[32px] h-[32px]": size === "xs",
          "w-[40px] h-[40px]": size === "sm",
          "w-[48px] h-[48px]": size === "md",
          "w-[64px] h-[64px]": size === "lg",
          "w-[82px] h-[82px]": size === "xl",
        }
      )}
      onClick={onClick}
    >
      <svg
        className="w-full h-full"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="grad1"
            x1="62.5"
            x2="13.5"
            y1="65.5"
            y2="4"
          >
            <stop offset="0.0317835" stopColor="#5295FC" />
            <stop offset="0.205818" stopColor="#743FF5" />
            <stop offset="0.492249" stopColor="#EA59CA" />
            <stop offset="0.730035" stopColor="#EB483F" />
            <stop offset="1" stopColor="#EB964D" />
          </linearGradient>
        </defs>
        <circle
          className={clsx(classes.circle1, {
            once: animation === "once",
            none: animation === "none",
            infinite: animation === "infinite",
            [classes.circle1Animation]: runAnimation,
          })}
          cx="50"
          cy="50"
          r="47"
          stroke="url(#grad1)"
        />
        {animation !== "none" && (
          <circle
            className={clsx(classes.circle2, {
              [classes.circle2Animation]: runAnimation,
            })}
            cx="50"
            cy="50"
            r="47"
          />
        )}
      </svg>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <Image
        alt="avatar"
        className={clsx(
          "object-cover absolute -translate-x-2/4 -translate-y-2/4 rounded-full left-2/4 top-2/4",
          {
            "w-[24px] h-[24px]": size === "xs",
            "w-[32px] h-[32px]": size === "sm",
            "w-[40px] h-[40px]": size === "md",
            "w-[56px] h-[56px]": size === "lg",
            "w-[70px] h-[70px]": size === "xl",
          }
        )}
        src={imgSrc}
      />
    </Component>
  )
}

export default StoryAvatar
