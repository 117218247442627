import { useCallback, useEffect, useRef, useState } from "react"

const useHasVisitedElement = () => {
  const [hasVisited, setHasVisited] = useState<boolean>(false)
  const divRef = useRef<HTMLDivElement | null>(null)

  const checkDivVisibility = useCallback(() => {
    if (divRef.current && !hasVisited) {
      const rect = divRef.current.getBoundingClientRect()
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setHasVisited(true)
      }
    }
  }, [hasVisited])

  useEffect(() => {
    window.addEventListener("scroll", checkDivVisibility)
    return () => {
      window.removeEventListener("scroll", checkDivVisibility)
    }
  }, [checkDivVisibility])

  return { ref: divRef, hasVisited }
}

export default useHasVisitedElement
