import React from "react"

import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import Medals from "public/assets/ito/snapshot-ito2022/medals.webp"
import Polaroid1 from "public/assets/ito/snapshot-ito2022/polaroid-1.webp"
import Polaroid2 from "public/assets/ito/snapshot-ito2022/polaroid-2.webp"
import Polaroid3 from "public/assets/ito/snapshot-ito2022/polaroid-3.webp"
import Polaroid4 from "public/assets/ito/snapshot-ito2022/polaroid-4.webp"
import Polaroid5 from "public/assets/ito/snapshot-ito2022/polaroid-5.webp"
import Polaroid6 from "public/assets/ito/snapshot-ito2022/polaroid-6.webp"
import Polaroid7 from "public/assets/ito/snapshot-ito2022/polaroid-7.webp"
import Polaroid8 from "public/assets/ito/snapshot-ito2022/polaroid-8.webp"
import Polaroid9 from "public/assets/ito/snapshot-ito2022/polaroid-9.webp"
import Polaroid10 from "public/assets/ito/snapshot-ito2022/polaroid-10.webp"
import { createUseStyles, useTheme } from "react-jss"

import SlidingBanner from "components/shared/SlidingBanner"

import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    alignItems: "center",
    gap: 16,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto auto",
    },
  },
  gradientText: {
    font: "inherit",
    wordBreak: "break-word",
    background:
      "linear-gradient(104deg, #EB964D 0%, #FF7879 30.21%, #AC83FF 67.19%, #1877F2 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    fontWeight: 600,
    "-webkit-text-fill-color": "transparent",
  },
  img: {
    filter: "drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.15))",
  },
}))

const linkedInPosts = [
  Polaroid1,
  Polaroid2,
  Polaroid3,
  Polaroid4,
  Polaroid5,
  Polaroid6,
  Polaroid7,
  Polaroid8,
  Polaroid9,
  Polaroid10,
]

const SnapshotITO2022 = ({ spacing }: { spacing: string }) => {
  const classes = useStyles()

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <div className={spacing} id="2022">
      <Container className="mb-3 md:mb-7 sm:mb-4">
        <SwitzerTypography
          className="mb-6 sm:text-center text-start"
          fontFamily="Switzer Bold"
          variant="title1"
        >
          Snapshots from ITO <span className="text-ito-lavendar-600">2022</span>
        </SwitzerTypography>
        <div className={classes.container}>
          <div>
            <SwitzerTypography fontFamily="Switzer Regular" variant="subtitle1">
              <span>
                Thousands of teachers stepped forward, embraced their growth and
                discovered their{" "}
                <span className={classes.gradientText}>
                  #TrueTeachingPotential
                </span>{" "}
                with ITO 2022. Below are a few glimpses of it.
              </span>
            </SwitzerTypography>
          </div>
          <div className="hidden sm:block">
            <Image
              alt="medals"
              className="max-w-[399px] mx-auto w-full"
              src={Medals}
            />
          </div>
        </div>
      </Container>
      <div>
        <SlidingBanner
          // eslint-disable-next-line react/no-unstable-nested-components
          component={(data, i) => (
            <div className="px-2 pt-4 pb-8 mx-1">
              <Image
                alt={`polaroid-${i}`}
                className={clsx("w-[260px]", classes.img, {
                  "rotate-[4deg]": i % 2 === 0,
                  "-rotate-[4deg]": i % 2 !== 0,
                })}
                loading="lazy"
                src={data}
              />
            </div>
          )}
          images={linkedInPosts}
          repeatImageTimes={4}
          speedTime={isXs ? 250 : 500}
        />
      </div>
    </div>
  )
}

export default SnapshotITO2022
