import React from "react"

import clsx from "clsx"
import Image, { StaticImageData } from "next/image"
import { createUseStyles } from "react-jss"

import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(() => ({
  container: {
    boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
    // display: "grid",
    // gridTemplateRows: "50% auto",
    // height: "100%",
  },
  data: {
    fontFamily: "Switzer Bold",
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.8px",
  },
  assess: {
    background:
      "linear-gradient(74deg, rgba(221,209,252,0.3) 0%, rgba(177,239,196,0.3) 100%)",
  },
  goals: {
    background:
      "linear-gradient(77deg, rgba(255,255,255,1) 0%, rgba(244,182,182,0.3) 18%, rgba(221,209,252,0.3) 71%, rgba(255,255,255,1) 100%)",
  },
  focus: {
    background:
      "radial-gradient(circle, rgba(195,214,251,0.6) 26%, rgba(233,225,253,0.6) 61%, rgba(255,255,255,1) 100%)",
  },
}))
type Props = {
  src: StaticImageData
  title: string
  cardName: "assess" | "goals" | "focus"
  className?: string
}

const Cards = ({ src, title, cardName, className }: Props) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.container,
        className,
        "rounded-lg overflow-hidden border border-surface-50 bg-surface-50 w-full"
      )}
    >
      <div className="flex justify-center bg-white">
        <Image alt={cardName} src={src} />
      </div>
      <div
        className={clsx(
          classes[cardName],
          "md:px-3 md:py-5 sm:px-2 sm:py-2.5 p-3 h-[50%]"
        )}
      >
        <SwitzerTypography fontFamily="Switzer Regular" variant="subtitle1">
          {title}
        </SwitzerTypography>
      </div>
    </div>
  )
}

export default Cards
