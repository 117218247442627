import React, { useEffect, useRef } from "react"

import Glide from "@glidejs/glide"
import { Container } from "@suraasa/placebo-ui-legacy"

import { NavArrowLeft, NavArrowRight } from "iconoir-react"

import { itoPageSections } from "../NavigationSection"
import SwitzerTypography from "../SwitzerTypography"

import Card from "./Card"
import { data } from "./data"

const SchoolLeaders = ({
  spacing,
  countryCode,
}: {
  spacing: string
  countryCode?: string
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    let glide: Glide | undefined
    if (ref.current) {
      glide = new Glide(`#${ref.current.id}`, {
        type: "slider",
        gap: 8,
        perView: 1,
        rewind: true,
        focusAt: 0,
        bound: true,
        peek: { before: 0, after: 0 },
      }).mount()
    }
    return () => {
      if (glide) glide.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  if (countryCode && countryCode !== "IN") {
    return null
  }
  return (
    <Container className={spacing}>
      <div
        className="overflow-hidden"
        id={itoPageSections.SCHOOL_LEADER_TESTIMONIAL}
      >
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          .glide__bullet{
            width: 6px;
            height: 6px;
            border-radius: 999px;
            background: #CBD5E1;
          }
          .glide__bullet--active{
            background: #64748B;
          }
        `,
          }}
        />
        <SwitzerTypography
          className="sm:!text-center !text-start text-ito-midnight sm:mb-[193px] mb-13"
          fontFamily="Switzer Bold"
          variant="title1"
        >
          School Leaders Recommend ITO
        </SwitzerTypography>
        <div className="relative" id="schoolSlider" ref={ref}>
          <div className="mt-4 glide__track" data-glide-el="track">
            <div
              className="flex glide__slides"
              style={{ listStyleType: "none" }}
            >
              {data.map((item, index) => (
                <Card data={item} key={index} />
              ))}
            </div>
          </div>

          <div
            className="slider__bullets glide__bullets dotSliderContainer z-[2] absolute inline-flex md:hidden bottom-2 w-full gap-1 items-center justify-center"
            data-glide-el="controls[nav]"
            style={{
              listStyle: "none",
            }}
          >
            {data.map((_, index) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                className="slider__bullet glide__bullet glide__bullet--active"
                data-glide-dir={`=${index}`}
                key={index}
              />
            ))}
          </div>

          <div
            className="absolute -left-0 top-[45%] md:block hidden"
            data-glide-el="controls"
          >
            <button
              className="w-3.5 h-3.5 rounded-full bg-surface-100 flex items-center justify-center"
              data-glide-dir="<"
              id="left"
            >
              <NavArrowLeft />
            </button>
          </div>

          <div
            className="absolute right-0 top-[45%] md:block hidden"
            data-glide-el="controls"
          >
            <button
              className="w-3.5 h-3.5 rounded-full bg-surface-100 flex items-center justify-center"
              data-glide-dir=">"
              id="right"
            >
              <NavArrowRight />
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SchoolLeaders
