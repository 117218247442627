import React, { useContext } from "react"

import { Button, ButtonProps } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Link from "next/link"

import { GlobalContext } from "components/GlobalContext"
import PreserveQueryParams from "components/shared/PreserveQueryParams"
import { TRACKING_DATA } from "utils/googleAnalytics"
import { getPlatformURL } from "utils/helpers"
import { getRegistrationStatus } from "utils/ito/utils"

import GradientButton from "../2023/landingPage/GradientButton"

const RegistrationStatusButton = ({
  className,
  fullWidth,
  timer,
  variant = "filled",
  gradientButton = false,
  ...props
}: ButtonProps & { timer?: JSX.Element; gradientButton?: boolean }) => {
  const { itoDetails } = useContext(GlobalContext)
  const registrationStatus = getRegistrationStatus(itoDetails)
  const CustomButton = gradientButton ? GradientButton : Button

  // const registrationsOver = true
  const waitlistPeriod = true

  // if (registrationsOver && registrationStatus !== "pending") {
  //   return (
  //     // @ts-expect-error Just a ts issue. Safe to do otherwise.
  //     <CustomButton
  //       className={className}
  //       component="a"
  //       data-feature={TRACKING_DATA.feature.ito}
  //       data-purpose={TRACKING_DATA.purpose.login}
  //       data-trackingid="ito-login-for-olympiad"
  //       fullWidth={fullWidth}
  //       href={getPlatformURL("learn", "/ito")}
  //       variant={variant}
  //       {...props}
  //     >
  //       Login for Olympiad
  //     </CustomButton>
  //   )
  // }

  if (waitlistPeriod === true) {
    return (
      <PreserveQueryParams
        href="/international-teachers-olympiad/register"
        render={href => (
          <CustomButton
            className={className}
            component={Link}
            fullWidth={fullWidth}
            href={href}
            prefetch={false}
            variant={variant}
            {...props}
          >
            Join the Waitlist
          </CustomButton>
        )}
      />
    )
  }

  if (registrationStatus === "notStarted" || registrationStatus === "pending") {
    const el = (
      <PreserveQueryParams
        href="/international-teachers-olympiad/register"
        render={href => (
          <CustomButton
            className={className}
            component={Link}
            data-feature={TRACKING_DATA.feature.ito}
            data-purpose={TRACKING_DATA.purpose.register}
            data-trackingid="ito-register-btn"
            fullWidth={fullWidth}
            href={href}
            prefetch={false}
            variant={variant}
            {...props}
          >
            {registrationStatus === "pending"
              ? "Complete Registration"
              : "Register Now"}
          </CustomButton>
        )}
      />
    )

    return timer ? (
      <div className={clsx(className, "flex items-center w-full gap-2")}>
        {timer}
        {el}
      </div>
    ) : (
      el
    )
  }

  return (
    // @ts-expect-error Just a ts issue. Safe to do otherwise.
    <CustomButton
      className={className}
      component="a"
      fullWidth={fullWidth}
      href={getPlatformURL("learn", "/ito")}
      variant={variant}
      {...props}
    >
      Go to dashboard
    </CustomButton>
  )
}

export default RegistrationStatusButton
