import React from "react"

import clsx from "clsx"
import Image from "next/image"
import GrowthReportIcon from "public/assets/olympiad/doubt-section/growth-report-icon.webp"
import { createUseStyles } from "react-jss"

import { itoSections } from "utils/constants"

import AboutTheOrganizer from "../AboutTheOrganizer"
import ReportSheet from "../ReportSheet"
import SwitzerTypography from "../SwitzerTypography"

// import Stories from "./Stories"

const useStyles = createUseStyles(theme => ({
  questionContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    justifyItems: "end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gridTemplateRows: "auto auto",
      gridTemplateColumns: "auto",
      justifyItems: "center",
    },
  },
}))

const WhatIsITOQuestion = () => {
  const classes = useStyles()
  return (
    <section
      className={clsx(classes.questionContainer, "px-2 sm:px-0 mb-3")}
      id={itoSections.ABOUT}
    >
      <div>
        <SwitzerTypography
          className="mb-4 !text-ito-midnight"
          fontFamily="Switzer Bold"
          variant="title1"
        >
          What is International Teachers’ Olympiad?
        </SwitzerTypography>
        <SwitzerTypography
          className="!text-ito-midnight mb-3"
          fontFamily="Switzer Regular"
          variant="body"
        >
          The International Teachers' Olympiad (ITO) is an opportunity for
          teachers to assess their pedagogical skills.
          <br />
          <br />
          During the Olympiad, you undergo a one-hour online assessment. Based
          on your performance, you receive a{" "}
          <ReportSheet
            render={() => (
              <a
                className="hover:underline"
                href="https://assets.suraasa.com/ito/2023/sample-teacher-report.pdf"
                rel="noreferrer"
                style={{ font: "inherit", color: "#3B82F6" }}
                target="_blank"
              >
                pedagogy report
              </a>
            )}
          />{" "}
          to track your progress year-on-year & growth plans to become even
          better educators.
          <br />
          <br />
          You can attempt the Olympiad without the fear of being judged. Your
          report will be 100% confidential & only accessible to you.
        </SwitzerTypography>
        <AboutTheOrganizer />{" "}
      </div>
      <div className="max-w-[386px] w-full">
        <Image alt="growth report img" src={GrowthReportIcon} />
      </div>
    </section>
  )
}

export default WhatIsITOQuestion
