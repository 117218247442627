import React, { useEffect, useRef, useState } from "react"

import { Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image, { StaticImageData } from "next/image"

import LoadingOverlay from "components/shared/LoadingOverlay"

export type StoryType = {
  name: string
  img: StaticImageData
  video: string
  position: string
  thumbnail: string
}
type Props = {
  data: StoryType | null
  className?: string
  onStoryEnd: () => void
}

const Story = ({ data, className, onStoryEnd }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const videoEl = videoRef.current
    const handleVideoEnd = () => {
      onStoryEnd()
    }
    const handleProgressBar = () => {
      if (videoEl) {
        const calcProgress = (videoEl.currentTime / videoEl.duration) * 100
        setProgress(calcProgress)
      }
    }
    if (videoEl) {
      videoEl.addEventListener("ended", handleVideoEnd)
      videoEl.addEventListener("timeupdate", handleProgressBar)
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("ended", handleVideoEnd)
        videoEl.removeEventListener("timeupdate", handleProgressBar)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) {
    return <LoadingOverlay />
  }
  const displayDetails = () => (
    <div className={clsx("flex justify-center items-center gap-1.5")}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <Image
        alt="avatar"
        className="object-cover w-6 h-6 rounded-full sm:h-9 shrink-0 sm:w-9"
        src={data.img}
      />
      <div>
        <Typography color="surface.500" textAlign="left" variant="title3">
          {data.name}
        </Typography>
        <Typography color="surface.500" textAlign="left" variant="body">
          {data.position}
        </Typography>
      </div>
    </div>
  )

  return (
    <div className={className}>
      <div className="!block rounded-lg relative mx-auto bg-black max-w-[260px] sm:max-w-[332px] md:max-w-fit w-full sm:h-[590px] md:h-[70vh] mb-1">
        <div className="absolute block -top-8 sm:hidden w-fit">
          {displayDetails()}
        </div>
        <div
          className="absolute w-[90%] mx-auto top-2 left-0 right-0 h-0.5 rounded-full  overflow-hidden"
          style={{
            background:
              "linear-gradient(90deg, rgba(105,219,123,1) 0%, rgba(52,109,242,1) 19%, rgba(116,63,245,1) 35%, rgba(234,89,202,1) 51%, rgba(235,72,63,1) 67%, rgba(235,150,77,1) 84%)",
          }}
        >
          <div
            className="absolute right-0 h-full bg-[#A1A1A1]"
            style={{
              width: `calc(100% - ${progress}%)`,
            }}
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          className="w-full h-full rounded-lg"
          controlsList="nodownload"
          id="video"
          poster={data.thumbnail}
          ref={videoRef}
          src={data.video}
          autoPlay
          controls
        />
      </div>
      <div className="hidden sm:block">{displayDetails()}</div>
    </div>
  )
}

export default Story
