import React, { useEffect, useRef, useState } from "react"

import Glide from "@glidejs/glide"
import { IconButton, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import ITOReviewsCardWeb from "public/assets/ito/what-is-ito/ito-reviews-card-web.webp"
import ITOReviewsMobile from "public/assets/ito/what-is-ito/ito-reviews-mobile.webp"
import ITOReviewsTab from "public/assets/ito/what-is-ito/ito-reviews-tab.webp"
import AartiShekhawat from "public/assets/ito/what-is-ito/teachers/aarti-shekhawat.webp"
import AnuradhaP from "public/assets/ito/what-is-ito/teachers/anuradha-p.webp"
import KalpitaK from "public/assets/ito/what-is-ito/teachers/kalpita-k.webp"
// import MariamRauf from "public/assets/ito/what-is-ito/teachers/mariam-rauf.webp"
import NeneIbezim from "public/assets/ito/what-is-ito/teachers/nene-ibezim.webp"
import ShilpiAgarwal from "public/assets/ito/what-is-ito/teachers/shilpi-agarwal.webp"
import SumitMandwani from "public/assets/ito/what-is-ito/teachers/sumit-mandwani.webp"
import SuvidhaSingh from "public/assets/ito/what-is-ito/teachers/suvidha-singh.webp"
import ZubaidaSubeen from "public/assets/ito/what-is-ito/teachers/zubaida-subeen.webp"
import { isIOS, isSafari } from "react-device-detect"
import { createUseStyles, useTheme } from "react-jss"

import { ArrowLeftCircle, ArrowRightCircle, Xmark } from "iconoir-react"

import NoSSR from "components/shared/NoSSR"
import SlidingSheet from "components/shared/SlidingSheet"
import useHasVisitedElement from "utils/hooks/useHasVisitedElement"

import Story, { StoryType } from "./Story"
import StoryAvatar from "./StoryAvatar"
import StoryCard from "./StoryCard"

const useStyles = createUseStyles(() => ({
  story: {
    width: 100,
    height: 100,
    position: "relative",
    overflow: "hidden",
    "& img": {
      width: 70,
      borderRadius: "50%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  sheetContainer: {
    background: "rgba(0, 0, 0, 0.80)",
  },
}))

const teacherStories: StoryType[] = [
  {
    name: "Nene Ibezim",
    img: NeneIbezim,
    video: "https://assets.suraasa.com/ito/2023/testimonial-videos/Nene_3.mp4",
    position: "Teacher Coach, Nigeria",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Nene%20Ibezim.webp",
  },
  {
    name: "Aarti Shekhawat",
    img: AartiShekhawat,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Aarti%20Shekhawat_2.mp4",
    position: "Teacher, Jaipur",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Aarti%20Shekhawat.webp",
  },
  {
    name: "Kalpita K",
    img: KalpitaK,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Kalpita%20K.mp4",
    position: "Teacher, Pune",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Kalpita%20K.webp",
  },
  // {
  //   name: "Mariam Rauf",
  //   img: MariamRauf,
  //   video:
  //     "https://assets.suraasa.com/ito/2023/testimonial-videos/Mariam%20Rauf_5.mp4",
  //   position: "English Teacher, Kerala",
  //   thumbnail:
  //     "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Mariam%20Rauf.webp",
  // },
  {
    name: "Shilpi Agarwal",
    img: ShilpiAgarwal,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Shilpi%20Agarwal_5.mp4",
    position: "Computer Teacher, Lucknow",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Shilpi%20Agarwal.webp",
  },
  {
    name: "Sumit Mandwani",
    img: SumitMandwani,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Sumit%20Mandwani_4.mp4",
    position: "Teacher, Noida",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Sumit%20Mandwani.webp",
  },
  {
    name: "Suvidha Singh",
    img: SuvidhaSingh,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Suvidha%20Singh_4.mp4",
    position: "Grade 7 Teacher, Gurugram",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Suvidha%20Singh.webp",
  },
  {
    name: "Zubaida Sabeen",
    img: ZubaidaSubeen,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Zubaida%20Sabeen_1.mp4",
    position: "Grade 2 teacher, Pakistan",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Zubaida%20Sabeen.webp",
  },
  {
    name: "Anuradha P",
    img: AnuradhaP,
    video:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/Anuradha%20P.mp4",
    position: "Global Perspectives Teacher, Kerala",
    thumbnail:
      "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/webp/Anuradha%20P.webp",
  },
]

const Stories = ({ isStoryCard }: { isStoryCard?: boolean }) => {
  const classes = useStyles()

  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [story, setStory] = useState<StoryType | null>(null)
  const storyCardSlider = useRef<HTMLDivElement | null>(null)

  const [glide, setGlide] = useState<Glide>()
  const [pauseAnimation, setPauseAnimation] = useState(false)

  const { ref, hasVisited } = useHasVisitedElement()

  const nextStep = () => {
    if (glide) {
      glide.go(">")
    }
  }

  const prevStep = () => {
    if (glide) {
      glide.go("<")
    }
  }

  const onClose = () => {
    setStory(null)
  }

  useEffect(() => {
    const instance = new Glide(`#glide`, {
      type: "slider",
      perView: 4.5,
      rewind: true,
      focusAt: 0,
      bound: true,
      peek: { before: 0, after: 0 },
      breakpoints: {
        [theme.breakpoints.values.lg - 1]: {
          perView: 3.8,
        },
        [theme.breakpoints.values.md]: {
          perView: 2.5,
        },
        [theme.breakpoints.values.sm]: {
          perView: 1.75,
        },
      },
    })

    if (storyCardSlider.current) {
      instance.mount()
      const mount = instance.mount()
      setGlide(mount)
    }

    return () => {
      if (glide) {
        glide.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyCardSlider.current])

  return (
    <NoSSR>
      <div className="relative px-1" ref={ref}>
        {!isStoryCard && (
          <div className="flex items-center gap-2 py-1.25 pl-1 overflow-x-auto overflow-y-hidden">
            {teacherStories.map((item, index) => (
              <StoryAvatar
                animation={
                  // eslint-disable-next-line no-nested-ternary
                  isIOS || isSafari || pauseAnimation
                    ? "none"
                    : index === 0
                    ? "infinite"
                    : "once"
                }
                className="shrink-0"
                imgSrc={item.img}
                key={index}
                runAnimation={hasVisited}
                size="xl"
                onClick={() => {
                  setPauseAnimation(true)
                  setStory(item)
                }}
              />
            ))}
          </div>
        )}
        {isStoryCard && (
          <div
            className="relative w-full mt-9"
            id="glide"
            ref={storyCardSlider}
          >
            <div
              className="w-full mb-2 overflow-hidden glide__track"
              data-glide-el="track"
            >
              <ul className="flex gap-2 glide__slides">
                {teacherStories.map((item, index) => (
                  <StoryCard
                    className="shrink-0 h-[251px]"
                    data={item}
                    key={index}
                    onClick={() => {
                      setPauseAnimation(true)
                      setStory(item)
                    }}
                  />
                ))}
              </ul>
            </div>
            <div className="flex justify-start mt-4" data-glide-el="controls">
              <button className="mr-1" data-glide-dir="<" onClick={prevStep}>
                <ArrowLeftCircle className="w-4 h-4" />
              </button>
              <button data-glide-dir=">" onClick={nextStep}>
                <ArrowRightCircle className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}
        {!isStoryCard && (
          <div className="absolute -top-4 sm:-bottom-8 sm:top-auto sm:left-auto left-2">
            <Image
              alt="ito-reviews-web"
              className="md:max-w-[194px] sm:max-w-[184px] max-w-[200px]"
              src={isXsDown ? ITOReviewsMobile : ITOReviewsTab}
            />
          </div>
        )}
        {isStoryCard && (
          <div className="absolute left-0 sm:-left-2 -top-8">
            <Image
              alt="ito-reviews-web"
              className="md:max-w-[194px] sm:max-w-[184px] max-w-[200px]"
              src={isXsDown ? ITOReviewsMobile : ITOReviewsCardWeb}
            />
          </div>
        )}
      </div>

      <SlidingSheet
        className={clsx(classes.sheetContainer, "relative overflow-hidden")}
        open={Boolean(story)}
        fullScreen
        unmountOnExit
        onClose={onClose}
      >
        <div className="relative h-full">
          <IconButton
            className="absolute z-[2] top-3 right-3"
            color="white"
            size="lg"
            onClick={onClose}
          >
            <Xmark fontSize={30} />
          </IconButton>

          <Story
            className="absolute w-full -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4"
            data={story}
            onStoryEnd={onClose}
          />
        </div>
      </SlidingSheet>
    </NoSSR>
  )
}

export default Stories
