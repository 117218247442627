import React from "react"

import { Container } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import ForSchoolPng from "public/assets/ito/for-school/for-school.webp"
import { createUseStyles } from "react-jss"

import GradientButton from "../GradientButton"
import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(theme => ({
  gradientBg: {
    backgroundColor: "white",
    backgroundImage:
      "radial-gradient(at 27% 13%, rgba(204, 186, 250, 0.5) 0, transparent 50%),radial-gradient(at 75% 21%, rgba(219, 234, 254, 1) 0, transparent 55%),radial-gradient(at 19% 75%, rgba(249, 217, 242, 1) 0, transparent 55%),radial-gradient(at 70% 75%, rgba(204, 186, 250, 1) 0, transparent 55%)",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    alignItems: "center",
    gap: "0px",
    [theme.breakpoints.down("xs")]: {
      gap: "73px",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto auto",
    },
  },
}))
const ForSchool = ({ spacing }: { spacing: string }) => {
  const classes = useStyles()
  return (
    <Container className={spacing}>
      <div className={classes.container}>
        <div>
          <SwitzerTypography
            className="!text-ito-midnight mb-1"
            fontFamily="Switzer Bold"
            variant="title1"
          >
            For Schools
          </SwitzerTypography>
          <SwitzerTypography
            className="!text-ito-midnight sm:max-w-[349px] w-full block mb-4"
            fontFamily="Switzer Regular"
            variant="largeBody"
          >
            Get Targeted CPD Plans for Your Schools when your teachers
            participate in ITO 2023.
          </SwitzerTypography>
          <GradientButton
            component={Link}
            href="/international-teachers-olympiad/school"
          >
            Find Out How
          </GradientButton>
        </div>
        <div>
          <div
            className={clsx(
              classes.gradientBg,
              "md:h-[338px] sm:h-[243px] h-[224px] relative bg-critical-100 rounded-2xl overflow-hidden shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)]"
            )}
          >
            <Image
              alt="for-school"
              className="absolute bottom-0 sm:w-full w-[435px]"
              src={ForSchoolPng}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ForSchool
