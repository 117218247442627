/* eslint-disable react/no-danger */
import React from "react"

const schemas = [
  {
    "@context": "http://schema.org",
    "@type": "Event",
    name: "International Teachers' Olympiad",
    description:
      "International Teachers' Olympiad gives you a chance to get respect, recognition & huge cash rewards for your teaching skills. Register for ITO 2023 now!",
    startDate: "2023-08-15T00:00",
    endDate: "2023-09-30T23:59",
    location: {
      "@type": "VirtualLocation",
      name: "Online via Suraasa Web Portal",
      url: "https://www.suraasa.com/international-teachers-olympiad",
    },
    organizer: {
      "@type": "EducationalOrganization",
      name: "Suraasa",
      url: "https://www.suraasa.com/",
    },
  },
  {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: "International Teachers' Olympiad",
    image: "URL_of_the_image_if_available",
    description:
      "International Teachers' Olympiad gives you a chance to get respect, recognition & huge cash rewards for your teaching skills. Register for ITO 2023 now!",
    brand: { "@type": "Thing", name: "Suraasa" },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.5",
      reviewCount: "4253",
    },
  },
  {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is the eligibility to participate in the International Teachers’ Olympiad?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "All teachers - aspiring, in-service and retired are eligible to participate in the International Teachers’ Olympiad.",
        },
      },
      {
        "@type": "Question",
        name: "Which subject teachers can participate in the Olympiad?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The Olympiad is not subject specific. This means any teacher having any subject expertise can participate in the Olympiad. It will consist of questions based on teaching approaches & skills.",
        },
      },
      {
        "@type": "Question",
        name: "Where can I attempt this test?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "You can attempt this test on your desktop, laptop, tablet, or mobile phone by signing into the Suraasa website or Android/iOS App using your registered email address or mobile number.",
        },
      },
      {
        "@type": "Question",
        name: "Will I have to pay anything apart from registration fee?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The fee to register yourself for International Teachers' Olympiad is ₹549. This is the only amount you will be paying throughout your journey. No hidden or extra charges.",
        },
      },
      {
        "@type": "Question",
        name: "Are previous year's question papers provided for the Olympiad preparation?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "No, previous year's question papers are not provided or necessary for the Olympiad. The Olympiad is designed for teachers to assess their current skills and knowledge. Therefore, by attempting the Olympiad unprepared, teachers can gain insights into their present standing and identify areas that require further development.",
        },
      },
      {
        "@type": "Question",
        name: "Will I be getting any certificate?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, all the participants will be getting an international certificate of participation. There will also be certificates of achievement for the top performers.",
        },
      },
      {
        "@type": "Question",
        name: "Will you share my report with my school?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "No, we will not share your report with your school. Your report is 100% confidential and only you will be able to access it by signing into your Suraasa account. The Olympiad has been created to be a safe space for teachers to get a fair assessment without fear of judgement.",
        },
      },
      {
        "@type": "Question",
        name: "Is the Olympiad all about the assessment and the report?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "No, the Olympiad is much more about the assessment & report. If you qualify in Top 100, you will get a cash prize of ₹10,000. Further, as a Top 100 participant, you will get to participate in Teacher Impact Awards with a chance to win cash prizes up to ₹3,00,000. Moreover, your personalised pedagogy report opens doorways to your professional growth & gives you a chance to move onto the next level of your professional journey with data-driven insights & comprehensive inputs from education experts.",
        },
      },
      {
        "@type": "Question",
        name: "Who is the Organiser of the International Teachers’ Olympiad?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The International Teachers’ Olympiad is being organised by Suraasa - world’s leading platform for teacher upskilling. To know more about Suraasa, click here.",
        },
      },
    ],
  },
  [
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Riju Singh",
      },
      reviewBody:
        "The Olympiad's personalised report was incredibly helpful. It provided a clear understanding of my performance and where I stand not only in India but also on an international level.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Nene Ibezim",
      },
      reviewBody:
        "The personalised report left me amazed by its level of detail & feedback. It has inspired me to be intentional about my growth. It gave me a chance to track my progress as a Teacher.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Priya Raushan",
      },
      reviewBody:
        "When I was at the airport, I eagerly downloaded my report. It stated I was in the top 1 percentile. I can’t even express how happy it made me.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Mukta",
      },
      reviewBody:
        "The assessment challenged me to think beyond theoretical knowledge. Moreover, the report given was my personal favourite. It gave me insight into how a teacher actually should be.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Michelle Fernandez",
      },
      reviewBody:
        "The personalised report was insightful. I got detailed feedback and the opportunity to gauge my teaching abilities through the report. It was great.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Farzana Dohadwala",
      },
      reviewBody:
        "I am very excited. This is a very great concept at a very very minimal cost. It gives you what you need to do to improve your teaching prowess.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Sandeep Hooda",
      },
      reviewBody:
        "Professional Development is very important in today’s time. This project enables teachers to get a great sense of their existing teaching skills & analyse their effectiveness.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Manisha Walia",
      },
      reviewBody:
        "The International Teachers Olympiad is an amazing platform that enhances teachers' skills and builds their confidence. It's a great opportunity for educators to grow and excel in their profession.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Suhas Jadhav",
      },
      reviewBody:
        "The Olympiad process and support are truly remarkable. It facilitates self-assessment of the academic and interpersonal skills of teachers. And then it helps them to improve their competency through personalised reports & growth plans.",
    },
    {
      "@context": "http://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "WebPage",
        name: "International Teachers’ Olympiad",
        url: "https://www.suraasa.com/international-teachers-olympiad",
      },
      author: {
        "@type": "Person",
        name: "Deepika Jaidas",
      },
      reviewBody:
        "I highly recommend International Teachers' Olympiad to all aspiring and practicing teachers. It will provide you valuable feedback and a roadmap for skill improvement. Plus, it will reignite your passion for teaching while helping you stay updated with the latest pedagogical practices.",
    },
  ],
]

const JsonSchema = () => (
  <>
    {schemas.map((schema, i) => (
      <script
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        key={i}
        type="application/ld+json"
      />
    ))}
  </>
)

export default JsonSchema
