import React from "react"

import clsx from "clsx"
import Image from "next/image"
import GridSquare from "public/assets/ito/hero-section/grid-square-light.svg"
import { createUseStyles } from "react-jss"

import { Testimony } from "../ReapRewardReport/TestimonialCard"
import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(theme => ({
  imgContainer: {
    border: "18px solid white",
    [theme.breakpoints.down("sm")]: {
      border: "10px solid white",
    },
  },
  quotes: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "103%",
    letterSpacing: "-0.64px",
  },
  name: {
    display: "inline-block",
    background:
      "linear-gradient(147deg, #EB483F 0%, #EA59CA 51.04%, #743FF5 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  container: {
    background: `linear-gradient(90deg, rgba(105,219,123,0.15) 0%, rgba(235,150,77,0.15) 25%, rgba(235,72,63,0.15) 41%, rgba(234,89,202,0.15) 57%, rgba(52,109,242,0.15) 78%, rgba(116,63,245,0.15) 91%),url(${GridSquare.src})`,
  },
}))
type Props = {
  data: Testimony
  className?: string
}

const Card = ({ data, className }: Props) => {
  const classes = useStyles()
  const { message, name, position, image } = data
  return (
    <div className={className}>
      <div
        className={clsx(
          "md:w-[calc(100%-_84px)] w-full mx-auto bg-critical-500 rounded-2xl relative md:pt-18 pt-9 sm:pt-14 md:pb-6 sm:pb-4 pb-5 px-2 min-h-[363px]",
          classes.container
        )}
      >
        <div
          className={clsx(
            classes.imgContainer,
            "bg-white sm:w-[224px] sm:h-[224px] w-[140px] h-[140px] rounded-full absolute sm:top-[-112px] sm:left-[calc(50%_-_112px)] top-[-70px] left-[calc(50%_-_70px)] overflow-hidden"
          )}
        >
          <Image alt="img" className="w-full" src={image} />
        </div>
        <SwitzerTypography
          className="w-full mx-auto mb-3 text-center max-w-none md:mb-5 sm:mb-5 !max-w-[850px]"
          fontFamily="Switzer Regular"
          variant="subtitle1"
        >
          {" "}
          <span className={clsx("text-ito-midnight", classes.quotes)}>
            “
          </span>{" "}
          {message}
          <span className={clsx("text-ito-midnight", classes.quotes)}>”</span>
        </SwitzerTypography>
        <div className="text-center">
          <SwitzerTypography
            className={classes.name}
            fontFamily="Switzer Bold"
            variant="title3"
          >
            {name}
          </SwitzerTypography>
          <SwitzerTypography
            color="onSurface.700"
            fontFamily="Switzer Regular"
            variant="largeBody"
          >
            {" "}
            ({position})
          </SwitzerTypography>
        </div>
      </div>
    </div>
  )
}

export default Card
