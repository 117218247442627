/* eslint-disable no-nested-ternary */

import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import { useRegistrationCloseTimer } from "components/registrationForm/utils/hooks/useRegistrationCloseTimer"
import NoSSR from "components/shared/NoSSR"

// import customTheme from "utils/customTheme"
// import ReviewButton from "../2023/landingPage/HeroSection/ReviewButton"
import SwitzerTypography from "../2023/landingPage/SwitzerTypography"

import ChatOnWhatsAppFAB from "./ChatOnWhatsAppFAB"
import RegistrationStatusButton from "./RegistrationStatusButton"

const useStyles = createUseStyles({
  container: {
    backgroundColor: "white",
    transition: "opacity 350ms",
    opacity: 0,
    position: "fixed",
    bottom: 0,
    zIndex: 10,
    width: "100%",
  },
  show: {
    opacity: 1,
  },
  floatingText: {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  },
})

const StickyRegister = ({
  hideWhatsApp = false,
}: {
  hideWhatsApp?: boolean
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const showBar = true

  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  // const isSm = useMediaQuery(theme.breakpoints.only("sm"))

  // const hideReviewImages = useMediaQuery("@media (max-width:500px)")
  // const hideReviews = useMediaQuery("@media (max-width:355px)")

  const { timeLeft, show, lessThan400px } = useRegistrationCloseTimer()

  // useEffect(() => {
  //   const listenToScroll = () => {
  //     const winScroll =
  //       document.body.scrollTop || document.documentElement.scrollTop
  //     if (winScroll > 300) {
  //       setShowBar(true)
  //     } else {
  //       setShowBar(false)
  //     }
  //   }

  //   window.addEventListener("scroll", listenToScroll)

  //   return () => {
  //     window.removeEventListener("scroll", listenToScroll)
  //   }
  // }, [])

  const flexShrink =
    typeof window === "undefined" ? 1 : window.innerWidth <= 360 ? 1 : 0

  return (
    <>
      {!hideWhatsApp && (
        <ChatOnWhatsAppFAB style={{ bottom: showBar ? 106 : undefined }} />
      )}

      <div
        className={clsx(
          "p-2 gap-2 border-t border-onSurface-200",
          classes.container,
          {
            [classes.show]: showBar,
          }
        )}
      >
        <NoSSR>
          <div
            className={clsx("flex items-center justify-between", {
              "gap-1": isXsDown,
              "gap-4": !isXsDown,
            })}
          >
            {/* {!hideReviews && <ReviewButton hidePhoto={hideReviewImages} />} */}

            <RegistrationStatusButton
              timer={
                show ? (
                  <div
                    className={clsx("w-[163px]", {
                      "w-auto": lessThan400px,
                    })}
                    style={{ flexShrink }}
                  >
                    <SwitzerTypography style={{ fontSize: 14 }}>
                      Registration Ends in
                    </SwitzerTypography>
                    <span className="text=[#121212] text-xl font-bold ">
                      {timeLeft}
                    </span>
                  </div>
                ) : undefined
              }
              fullWidth
              gradientButton
            />
          </div>
        </NoSSR>
        {/* <div className={clsx("mt-1", classes.floatingText)}>
          for Registered Users only
        </div> */}
      </div>
    </>
  )
}

export default StickyRegister
