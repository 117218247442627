import React from "react"

import Certificates from "public/assets/ito/reward-report/growth-and-support/certificates.webp"
import Masterclasses from "public/assets/ito/reward-report/growth-and-support/masterclasses.webp"
import Reports from "public/assets/ito/reward-report/growth-and-support/reports.webp"

import { itoPageSections } from "../../NavigationSection"
import SwitzerTypography from "../../SwitzerTypography"

import Card from "./Card"

type Props = {
  className?: string
}

const GrowAndSupport = ({ className }: Props) => (
  <div className={className} id={itoPageSections.GROWTH}>
    <SwitzerTypography
      className="!text-ito-midnight block mb-5"
      fontFamily="Switzer Bold"
      textAlign="center"
      variant="title1"
    >
      6 Months of Career Growth & Support
    </SwitzerTypography>
    <div className="grid grid-cols-12 gap-3 mb-4">
      <Card
        cardName="reports"
        className="col-span-12 md:col-span-4 sm:col-span-6"
        src={Reports}
        subTitle="for Continuous Reflection"
        title="6 Reports"
      />
      <Card
        cardName="certificates"
        className="col-span-12 md:col-span-4 sm:col-span-6"
        src={Certificates}
        subTitle="for Continuous Recognition"
        title="6 Certificates"
      />
      <Card
        cardName="masterclasses"
        className="col-span-12 md:col-span-4 sm:col-span-6 sm:col-start-4 md:col-start-9"
        src={Masterclasses}
        subTitle="for Continuous Learning"
        title="6 Masterclasses"
      />
    </div>
  </div>
)

export default GrowAndSupport
