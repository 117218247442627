import React from "react"

import { Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import ReviewPhoto from "public/assets/ito/hero-section/review-button/review-photo.webp"
import { createUseStyles } from "react-jss"

import { Star } from "iconoir-react"

import { RatingAndReviewId } from "utils/constants"

type Props = {
  className?: string
  hidePhoto?: boolean
  isRatingReviewPage?: boolean
  glassEffect?: boolean
}

const useStyles = createUseStyles(() => ({
  halfStar: {
    clipPath: "polygon(0% 0%,50% 0%,50% 100%,0% 100%)",
  },
  ratingAndReviewPage: {
    fontFamily: "Inter",
    fontSize: 8,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}))

const ReviewButton = ({
  className,
  hidePhoto,
  isRatingReviewPage,
  glassEffect,
}: Props) => {
  const classes = useStyles()
  const svgProps = isRatingReviewPage
    ? { width: "15px", height: "15px" }
    : {
        width: "20px",
        height: "20px",
      }
  return (
    <a
      className={clsx("flex items-center w-fit cursor-pointer", className, {
        "bg-white/50 rounded-2xl": glassEffect,
        "bg-white rounded-xl": !glassEffect,
      })}
      href={`#${RatingAndReviewId}`}
    >
      {!hidePhoto && (
        <Image
          alt="review-photo"
          className={clsx("mr-1 shrink-0 ", {
            "!h-full !object-contain w-fit": isRatingReviewPage,
          })}
          src={ReviewPhoto}
        />
      )}
      <div>
        <div className="flex gap-0.5">
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <Star {...svgProps} className="text-warning-400" fill="#EEC239" />
          <div className="relative">
            <Star {...svgProps} className="text-[#E2E8F0]" fill="#E2E8F0" />
            <Star
              {...svgProps}
              className={clsx(
                classes.halfStar,
                "absolute top-0 left-0 text-warning-400"
              )}
              fill="#EEC239"
            />
          </div>
        </div>
        <Typography
          className={clsx({
            [classes.ratingAndReviewPage]: isRatingReviewPage,
          })}
          color="onSurface.800"
          variant="smallBody"
        >
          4.5/5 stars in 4253+ reviews
        </Typography>
      </div>
    </a>
  )
}

export default ReviewButton
