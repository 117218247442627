import React from "react"

import { Button, Container, IconButton } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import Facebook from "public/assets/ito/sharing-section/facebook.svg"
import Linkedin from "public/assets/ito/sharing-section/linkedin.svg"
import Twitter from "public/assets/ito/sharing-section/twitter.svg"
import WhatsappImg from "public/assets/ito/sharing-section/whatsapp-img.webp"
import WhatsappSvg from "public/assets/ito/sharing-section/WhatsappSvg"
import { createUseStyles } from "react-jss"

import { popupWindow } from "components/shared/SocialShareDialog"
import { getPlatformURL } from "utils/helpers"

import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(() => ({
  whatsappButton: {
    background: "linear-gradient(135deg, #128C7E 0%, #5FAF6C 100%)",
  },
  container: {
    boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.10)",
    backgroundColor: "#ffffff",
    backgroundImage:
      "radial-gradient(at 0% 80%, rgba(177, 239, 196, 0.5) 0px, transparent 59%), radial-gradient(at 30% 10%, rgba(165, 194, 248, 0.5) 0px, transparent 55%), radial-gradient(at 100% 40%, rgba(202, 244, 215, 0.5) 0px, transparent 55%)",
  },
}))

const SharingSection = ({ className }: { className?: string }) => {
  const classes = useStyles()

  const url = getPlatformURL("suraasa", "/international-teachers-olympiad")
  const text = encodeURIComponent(
    `Hello,\nI came across International Teachers' Olympiad 2023. It's an incredible opportunity for teachers like us to get recognised & rewarded for our hard work & expertise.\n\nYou might want to explore it too, take a look: \n${url}`
  )

  const LINKEDIN_URL = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`

  const FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`

  const WHATSAPP_URL = `https://api.whatsapp.com/send?text=${text}`

  const TWITTER_URL = `https://twitter.com/intent/tweet?url=${text}`
  return (
    <Container>
      <div className={className}>
        <div
          className={clsx(
            classes.container,
            "md:p-7 sm:p-3 px-2 pt-5 pb-0 grid sm:grid-cols-[50%_50%] sm:grid-rows-[auto]  grid-rows-[auto_auto] grid-cols-[auto] rounded-2xl relative md:gap-0 gap-2.5"
          )}
        >
          <div>
            <SwitzerTypography
              className="mb-1"
              fontFamily="Switzer Bold"
              variant="title3"
            >
              Invite your Friends & Colleagues
            </SwitzerTypography>
            <SwitzerTypography
              className="mb-3 md:max-w-none sm:max-w-[343px] w-full"
              fontFamily="Switzer Regular"
            >
              Share this Olympiad with your friends and colleagues. Help them
              grow & get recognised in their teaching careers!
            </SwitzerTypography>
            <div className="flex items-center">
              <Button
                className={clsx(
                  classes.whatsappButton,
                  "!rounded-full me-2 md:!block !hidden"
                )}
                startAdornment={<WhatsappSvg className="shrink-0" />}
                onClick={() => popupWindow(WHATSAPP_URL, 650, 650)}
              >
                Share on Whatsapp
              </Button>
              <IconButton
                className={clsx(
                  classes.whatsappButton,
                  "shrink-0 !rounded-full me-1  md:!hidden !grid"
                )}
                size="md"
                variant="filled"
                onClick={() => popupWindow(WHATSAPP_URL, 650, 650)}
              >
                <WhatsappSvg className="shrink-0" />
              </IconButton>

              <IconButton
                className="shrink-0 !rounded-full me-1"
                color="black"
                size="md"
                variant="filled"
                onClick={() => popupWindow(LINKEDIN_URL)}
              >
                <Image alt="linkedin" src={Linkedin} />
              </IconButton>
              <IconButton
                className="shrink-0 !rounded-full me-1"
                color="black"
                size="md"
                variant="filled"
                onClick={() => popupWindow(FACEBOOK_URL)}
              >
                <Image alt="facebook" src={Facebook} />
              </IconButton>
              <IconButton
                className="shrink-0 !rounded-full"
                color="black"
                size="md"
                variant="filled"
                onClick={() => popupWindow(TWITTER_URL)}
              >
                <Image alt="twitter" src={Twitter} />
              </IconButton>
            </div>
          </div>
          <div className="relative">
            <Image
              alt="whatsapp-img"
              className="object-contain sm:max-h-none max-h-[350px] sm:h-[310px] h-full mx-auto sm:absolute sm:-bottom-3 md:-bottom-7 md:right-7 md:left-auto sm:left-0 sm:right-0 mx-auto w-fit"
              src={WhatsappImg}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SharingSection
