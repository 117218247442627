import { useEffect, useState } from "react"

import { useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { isAfter } from "date-fns"
import { useTimer } from "react-timer-hook"

import api from "api"

const labels = ["d", "h", "m", "s"]

export const useRegistrationCloseTimer = () => {
  const [show, setShow] = useState(false)
  const lessThan400px = useMediaQuery(
    "@media only screen and (max-width: 400px)"
  )

  const { days, hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setShow(false),
    autoStart: false,
  })

  useEffect(() => {
    async function fetch() {
      const res = await api.registrationForm.examination.getDate({
        params: { tag: "dashboard" },
      })
      if (res.isSuccessful) {
        const date = res.data.find(
          i => i.slug === "registrations-end-2023"
        )?.date

        if (date) {
          const dateObj = new Date(date)

          if (isAfter(dateObj, new Date())) {
            setShow(true)
            restart(dateObj, true)
          }
        }
      } else {
        console.error(res.errors)
      }
    }
    fetch()
  }, [restart])

  const timeLeft = [days, hours, minutes, lessThan400px ? undefined : seconds]
    .filter((element): element is number => typeof element === "number")
    .map(
      (i, index) =>
        `${index === 0 ? i : i.toString().padStart(2, "0")}${labels[index]}`
    )
    .join(" ")

  return {
    days,
    hours,
    minutes,
    seconds,
    show,
    lessThan400px,
    timeLeft,
  }
}
